.Contact {
  background-color: $bright-backround;
  color: $site-white;
  text-align: left;
  padding: ($gutter * 2) $gutter;
}
.contact-container {
  max-width: $site-width;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 1.5px;
  @media only screen and (min-width: 720px) {
    text-align: left;
  }
}
