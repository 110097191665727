h2, .h2 {
  text-transform: uppercase;
  letter-spacing:1.2px;
  color: $site-white;
  font-size: 2em;

}
h3, .h3 {
  // text-transform: uppercase;
  // letter-spacing:1.2px;
  font-size:2.5rem;
  color: $site-white;
  margin: 0;
  padding: 0;
  line-height: 2.1rem;
}
h4, .h4 {
  text-transform: uppercase;
  letter-spacing:1.2px;
  color: $site-white;
  margin: .25rem auto;
}

h1, h2, h3, h4 {
  &.dark {
    color: $site-black;
  }
}

p {
  font-size: 1.2rem;
}
.right, .left {
  text-align: center;
}
.right {
  @media only screen and (min-width: 720px) {
    text-align: right;
  }
}
.left {
  @media only screen and (min-width: 720px) {
    text-align: left;
  }
}
.margin-top {
  margin-top: ($gutter);
  @media only screen and (min-width: 720px) {
    margin-top: ($gutter * 2);
  }
}
.margin-bottom {
  margin-top: ($gutter * 2);
}
.gutter-top {
  margin-top: $gutter;
}
.gutter-bottom {
  margin-bottom: $gutter;
}
.no-margin-top {
  margin-top: 0;
}
.no-margin-bottom {
  margin-bottom: 0;
}

li {
  list-style-type: none;
}
