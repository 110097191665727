body {
  margin: 0;
  // font-family: 'Poppins', sans-serif;
  font-family: 'Nanum Myeongjo', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: -1;
  margin: 0;
  padding: 0;
  text-align:center;
  font-weight: normal;
  line-height: 1.5;
  color: $site-black;
  background-color: $background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
footer, .content {
  max-width: 100%;
  @media only screen and (min-width: $site-width){
    max-width: $site-width;
    margin: auto;
  }
}

code {
  font-family: 'Rubik', sans-serif;
}

ul {
  margin-left: 0;
  padding-left: 1em;
  li {
    padding: ($gutter / 8) 0;
    span {
      margin-left: $gutter / 4;
    }
  }
}

a {
  color: $site-black;
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
}
