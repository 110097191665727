footer {
  padding: ($gutter * 2) $gutter $gutter;
}
footer, .footer {
  text-align: center;
  @media only screen and (min-width: 720px) {
    text-align: left;
  }
}
.footer-socials {
  li {
    display: inline-block;
    padding: 0 ($gutter / 2);
    @media only scree and (min-width: 720px) {

    }
  }
}

.copyright-info {
  margin: $gutter auto 0x;
}
