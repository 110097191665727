.stats--container {
  padding: $gutter 0;
}

.stats-button {
  background-color: $site-blue;
  color: $site-white;
  width: 100px;
  margin: auto;
  padding: 15px 0;
  border-radius: 7.5px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 2.2px;
  transition: $normal-transition;
  cursor: pointer;
  &:hover {
    background-color: $site-white;
    color: $site-blue;
  }
}

.stats-content__wrapper {
  text-align: left;
  padding: $gutter 0 ($gutter / 2);
}

.stats-content {
  height: auto;
  overflow: hidden;
  max-height: 0;
  transition: $normal-transition;
  &.is-active {
    max-height: 10000px;
  }
}
