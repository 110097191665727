button.btn {
  padding: ($gutter / 2) $gutter;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1.1px;
  background-color: $button-light;
}
.button-light {
  padding: $gutter;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1.1px;
  background-color: $button-light;
  color: $site-black;
  border: 1px solid $button-light;
  transition: $normal-transition;
  &:hover {
    color: $button-light;
    background-color: $site-black;
    border: 1px solid $button-light;
  }

}
.button-dark, .demo-button {
  color: $site-white;
  background-color: $site-red;
  padding: 15px;
  border-radius: 5px;
  font-weight: 800;
  border: 1px solid $site-red;
  transition: $normal-transition;
  margin: ($gutter / 2);
  @media only screen and (min-width: 720px){
    margin: $gutter 0;
  }
  &:hover {
    color: $site-red;
    background-color: $site-white;
    border: 1px solid $site-red;
  }
}
