$gutter: 30px;

$box-height: 250px;

$page-width: 960px;
$site-width: 1040px;

$normal-transition: all .75s;
$slow-transition: all 1.5s ease;
$quick-transition: all .25s;


/* colors */
$background: rgb(246, 247, 235);
$primary-text: #1C3144;
$bright-backround: #40140f;
$site-red: #b3061b;
$site-white: rgb(246, 247, 235);

$primary-color: #1C3144;
$complementary-color: #442f1c;
$tagline-color: #b3061b;

$button-light: rgb(213, 210, 205);
$button-dark: #40140f;

$site-black: #000;
$site-white: #FFF;
$site-blue: #1C3144;
$site-gray: rgb(213, 210, 205);
$site-grey: rgb(213, 210, 205);
$background-gray: rgba(213, 210, 205, 0.95);
$site-highlight: #0F9591;
$site-green: #368F8B;
$site-bright-red: #BF211E;
$alert: #BF211E;
$form-red: #FF0000;

.alert {
  color: $site-bright-red;
}
.black {
  color: $site-black;
}
.white {
  color: $site-white;
}
.gray {
  color: $site-gray;
}
.dark-gray {
  color: $background;
}
.green {
  color: $site-green;
}
.red {
  color: $site-red;
}
.form-red {
  color: $form-red !important;
}
.form-green {
  color: $site-green !important;
}
