.ContentBlock {
  margin: ($gutter) auto;
  opacity: 0;
  transition: $slow-transition;
  h3 {
    color: $site-black;
    padding-top: $gutter / 4;
    margin: $gutter;
  }
  li {
    list-style-type: none;
  }
  @media only screen and (min-width: 720px) {
    margin: ($gutter * 2) auto;
    max-width: $site-width;
  }
  &.revealed{
    opacity: 1;
  }
}
.ContentBlock-content {
  position: relative;
  height:550px;
  width: $page-width;
  @media only screen and (min-width: 720px) {
    height: 450px;
  }
}
.ContentBlock--container {
  position: relative;
  max-width:100%;
  // min-height: 100%;
  z-index: 1000;
  padding: ($gutter / 2);
  margin-top: $gutter;
  @media only screen and (min-width: 720px) {
    position: relative;
    height: 100%;
    z-index: 1000;
    padding: ($gutter / 2);
    margin-top: 0;
    // transform: skew(-3deg);
  }
}
.ContentBlock--content-wrapper {
  position: relative;
  @media only screen and (min-width: 720px) {
    // position: absolute;
    // top: 50%;
    // transform: translatey(-50%);
    padding: $gutter ($gutter / 2);
  }
}

.ContentBlock--image-wrapper{
  position: relative;
  // width: 100%;
  // z-index: 999;
  @media only screen and (min-width: 720px) {
    position: absolute;
    // right: 0;
    width: 45%;
    // height:100%;
    top: 50%;
    transform: translatey(-50%);
    z-index: 999;
    img {
      width:100%;
      height:120%;
    }
  }
}
.image-block__mobile {
  // height:225px;
  width: 100%;
}

.more-info-block {
  // padding: $gutter / 2;
  max-width: $site-width;
  margin: ($gutter) auto;
  height: auto;
  max-height: 0px;
  overflow: hidden;
  transition: $normal-transition;
  &.expanded {
    overflow: inherit;
    max-height: 1000px;
  }
}
.MoreInfoBlock{
  opacity: 0;
  transition: $normal-transition;
  &.revealed {
    opacity: 1;
  }
}
