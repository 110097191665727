header {
  width:100%;
  // position:fixed;
  top:0;
  color: $site-black;
  h2 {
    font-size: 1.3em
  }
}
.header {
  max-width: 1040px;
  margin: 0 auto;
}
.headline-container {
  position: relative;
}
.headline {
  position: absolute;
  z-index: -1;
  color: transparent;
  background-color: transparent;
  opacity: 0;
  transform: none;
}
// logo
.logo-container {
  height: 250px;
  position: relative;
}
.logo-wrapper {
  // display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .logo {
    width: 200px;
  }
}
// definitions
.definition-container {
  height: 100%;
  width: 100%;
  position: relative;
  .definition-wrapper {
    position: absolute;
    top:50%;
    // left:50%;
    transform: translateY(-50%);
  }
  .part-of-speech {
    font-size: 0.8em;
    text-transform: lowercase;
    color: gray;
  }
  .definition {
    font-size: 0.8em;
    font-style: italic;
  }
}
